export default function accordion() {
  // eslint-disable-next-line no-undef
  Vue.component('accordion-item', {
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      async toggleLink() {
        if (this.isOpen) {
          const scrollTop = window.scrollY || window.pageYOffset;
          const rectTop = this.$el.getBoundingClientRect().top;
          const buffer = document.querySelector('.l-header').offsetHeight;
          const scrollPosition = rectTop + scrollTop - buffer;

          await $.when(
            $('html, body').animate(
              { scrollTop: scrollPosition },
              500,
              'swing',
            ),
          );
        }
        this.openStateChange();
      },
      openStateChange() {
        this.isOpen = !this.isOpen;
      },
      enter(enterElement) {
        const enterEl = enterElement;
        const enterElHeight = enterEl.getBoundingClientRect().height;
        enterEl.style.height = '0';
        // eslint-disable-next-line no-unused-expressions
        enterEl.offsetHeight; // reflow
        enterEl.style.height = `${enterElHeight}px`;
      },
      afterEnter(afterEnterElement) {
        const afterEnterEl = afterEnterElement;
        afterEnterEl.style.height = null;
      },
      leave(leaveElement) {
        const leaveEl = leaveElement;
        const leaveElHeight = leaveEl.getBoundingClientRect().height;
        leaveEl.style.height = `${leaveElHeight}px`;
        // eslint-disable-next-line no-unused-expressions
        leaveEl.offsetHeight; // reflow
        leaveEl.style.height = '0';
      },
      afterLeave(afterLeaveElement) {
        const afterLeaveEl = afterLeaveElement;
        afterLeaveEl.style.height = null;
      },
    },
    template: `
      <li class="c-accordion__item" :class="{_open: isOpen}">
        <slot name="head"></slot>
        <div class="c-accordion__child">
          <transition name="accordion" @enter="enter" @after-enter="afterEnter" @leave="leave" @after-leave="afterLeave">
            <div class="c-accordion__childInner" v-show="isOpen"><slot name="body"></slot></div>
          </transition>
        </div>
        <button class="c-accordion__link" @click="toggleLink"><slot name="link"></slot></button>
      </li>
    `,
  });
}

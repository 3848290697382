import { loadingFirstAccessFunc, loadingNotFirstAccessFunc } from './loading';

export default function cookie() {
  // Cookieに'visited'キーが存在するかチェック
  function getCookie(key) {
    const cookies = document.cookie;
    const cookiesArray = cookies.split(';');
    for (let i = 0; i < cookiesArray.length; i += 1) {
      const cookieValue = cookiesArray[i].split('=');
      if (cookieValue[0] === key) {
        return cookieValue;
      }
    }
    return 'undefined';
  }
  // Cookieにキーと値をセット
  function setCookie(key, value) {
    document.cookie = `${key}=${value};`;
  }

  const KEY_NAME = 'visited';
  const KEY_VALUE = 'true';
  const cookieValue = getCookie(KEY_NAME);

  if (typeof cookieValue === 'undefined' || cookieValue[1] !== KEY_VALUE) {
    // 初回アクセス時の処理
    setCookie(KEY_NAME, KEY_VALUE);
    loadingFirstAccessFunc();
  } else {
    // 二回目以降のアクセス時の処理
    loadingNotFirstAccessFunc();
  }
}

const options = {
  root: null,
  rootMargin: '-20% 0px',
  threshold: 0,
};
const inviewFunc = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('_inview');
      // eslint-disable-next-line no-use-before-define
      observer.unobserve(entry.target);
    }
  });
};
const observer = new IntersectionObserver(inviewFunc, options);

// eslint-disable-next-line import/prefer-default-export
export const mixinDirective = {
  directives: {
    inview: {
      bind(el) {
        observer.observe(el);
      },
    },
  },
};

function recruitSwiper() {
  // eslint-disable-next-line no-new, no-undef
  new Swiper('.c-swiper__container', {
    loop: true,
    speed: 3000,
    slidesPerView: 1.4,
    spaceBetween: 25,
    centeredSlides: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        slidesPerView: 1.5,
        spaceBetween: 25,
        centeredSlides: true,
      },
      1000: {
        spaceBetween: 30,
        slidesPerView: 4,
        centeredSlides: false,
      },
      1300: {
        spaceBetween: 50,
        slidesPerView: 4,
        centeredSlides: false,
      },
    },
  });
}

export default function slider() {
  recruitSwiper();
}

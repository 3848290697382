export default function accordionSp() {
  const matchMedia = window.matchMedia('(max-width:767px)');

  // eslint-disable-next-line no-undef
  Vue.component('accordion-sp-item', {
    data() {
      return {
        isOpen: false,
      };
    },
    mounted() {
      window.addEventListener('resize', this.checkMediaOpen);
      this.checkMediaOpen();
    },
    methods: {
      toggleLink() {
        if (matchMedia.matches) {
          this.isOpen = !this.isOpen;
        }
      },
      checkMediaOpen() {
        this.isOpen = !matchMedia.matches;
      },
      enter(enterElement) {
        const enterEl = enterElement;
        const enterElHeight = enterEl.getBoundingClientRect().height;
        enterEl.style.height = '0';
        // eslint-disable-next-line no-unused-expressions
        enterEl.offsetHeight; // reflow
        enterEl.style.height = `${enterElHeight}px`;
      },
      afterEnter(afterEnterElement) {
        const afterEnterEl = afterEnterElement;
        afterEnterEl.style.height = null;
      },
      leave(leaveElement) {
        const leaveEl = leaveElement;
        const leaveElHeight = leaveEl.getBoundingClientRect().height;
        leaveEl.style.height = `${leaveElHeight}px`;
        // eslint-disable-next-line no-unused-expressions
        leaveEl.offsetHeight; // reflow
        leaveEl.style.height = '0';
      },
      afterLeave(afterLeaveElement) {
        const afterLeaveEl = afterLeaveElement;
        afterLeaveEl.style.height = null;
      },
    },
    template: `
      <li class="c-accordionSp__item" :class="{_open: isOpen}">
        <button class="c-accordionSp__link" @click="toggleLink(event)"><slot name="parent"></slot></button>
        <transition name="accordion-sp" @enter="enter" @after-enter="afterEnter" @leave="leave" @after-leave="afterLeave">
          <div class="c-accordionSp__child" v-show="isOpen"><div class="c-accordionSp__childInner"><slot name="child"></slot></div></div>
        </transition>
      </li>
    `,
  });
}

export default function debounce() {
  const appElement = document.getElementById('app');
  function addTransitionClassFunc() {
    appElement.classList.add('_useTransition');
  }
  const debounceFunc = (fn, interval) => {
    let timer;
    return () => {
      appElement.classList.remove('_useTransition');
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn();
      }, interval);
    };
  };
  window.addEventListener('resize', debounceFunc(addTransitionClassFunc, 1000));
  addTransitionClassFunc();
}

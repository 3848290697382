export default function pagetop() {
  const targetEl = document.getElementById('js-pagetop');
  const watchEl = document.querySelector('.l-contents');
  const options = {
    root: null,
    rootMargin: '0px 0px -100% 0px',
    threshold: 0,
  };
  const showPagetop = (elements) => {
    elements.forEach((element) => {
      if (element.isIntersecting) {
        targetEl.classList.add('_show');
      } else {
        targetEl.classList.remove('_show');
      }
    });
  };
  const observer = new IntersectionObserver(showPagetop, options);
  observer.observe(watchEl);
}

export default function modal() {
  const body = document.getElementsByTagName('body');
  const addTriggerValue = document.querySelectorAll('.modal-trigger');
  const removeTriggerValue = document.querySelectorAll('.c-cardBlock__modalBg');
  let windowHeight;
  addTriggerValue.forEach((el) => {
    el.addEventListener('click', () => {
      windowHeight = window.scrollY;
      body[0].style.top = `-${windowHeight}px`;
      body[0].classList.add('_fixed');
      const modalTarget = el.nextElementSibling;
      modalTarget.style.display = '';
      modalTarget.classList.add('_fadeIn');
    });
  });
  removeTriggerValue.forEach((el) => {
    el.addEventListener('click', () => {
      body[0].style.top = '';
      body[0].classList.remove('_fixed');
      window.scrollTo(0, windowHeight);
      // eslint-disable-next-line no-param-reassign
      el.parentNode.style.display = 'none';
      el.parentNone.classList.remove('_fadeIn');
    });
  });
}

export function loadingFirstAccessFunc() {
  const loadingElement = document.getElementById('js-loading');
  const kvElement = document.querySelector('.p-kv');
  // CSSアニメーションが完了するまでの時間
  const ANIMATION_TIME = 3500;
  const loadFunc = () => {
    loadingElement.classList.add('_load');
    kvElement.classList.add('_load');
  };
  const loadedFunc = () => {
    loadingElement.classList.remove('_load');
    loadingElement.classList.add('_loaded');
  };
  if (loadingElement === null) return;

  window.addEventListener('load', () => {
    loadFunc();
    setTimeout(loadedFunc, ANIMATION_TIME);
  });
}

export function loadingNotFirstAccessFunc() {
  const loadingElement = document.getElementById('js-loading');
  const kvElement = document.querySelector('.p-kv');
  const initFunc = () => {
    loadingElement.style.display = 'none';
    kvElement.classList.add('_init');
  };
  if (loadingElement === null) return;

  initFunc();
}

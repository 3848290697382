import accordion from './accordion';
import accordionSp from './accordionSp';
import header from './header';
import footer from './footer';
import hoverBlowing from './hoverBlowing';
import { mixinDirective } from './inview';

accordion();
accordionSp();
header();
footer();
hoverBlowing();

export default function vueInstance() {
  const matchMedia = window.matchMedia('(max-width:999px)');
  // eslint-disable-next-line no-undef, no-new
  new Vue({
    el: '#app',
    mixins: [mixinDirective],
    data: {
      isTransitionClass: false,
      isHamburgerShow: false,
      isHamburgerNavShow: false,
      isHamburgerBorderShow: false,
      isOverlayShow: false,
    },
    mounted() {
      this.checkMedia();
      matchMedia.addListener(this.checkMedia);
    },
    methods: {
      hamburgerNavFadein() {
        this.isHamburgerNavShow = !this.isHamburgerNavShow;
      },
      hamburgerBorderFadein() {
        this.isHamburgerBorderShow = !this.isHamburgerBorderShow;
      },
      openHamburgerFunc() {
        if (this.isHamburgerShow) {
          this.$refs.button.setAttribute('aria-expanded', 'true');
        } else {
          this.$refs.button.setAttribute('aria-expanded', 'false');
        }
      },
      openHamburger() {
        this.isHamburgerShow = !this.isHamburgerShow;
        this.openHamburgerFunc();
        setTimeout(this.hamburgerBorderFadein, 300);
        setTimeout(this.hamburgerNavFadein, 500);
      },
      linkHamburgerChild() {
        this.isHamburgerShow = false;
        this.isHamburgerNavShow = false;
        this.isHamburgerBorderShow = false;
        this.$refs.button.setAttribute('aria-expanded', 'false');
        this.openHamburgerFunc();
        for (let i = 1; i < 6; i += 1) {
          this.$refs[`header${i}`].isOpen = false;
        }
      },
      checkMedia() {
        this.isHamburgerShow = !matchMedia.matches;
        this.isHamburgerNavShow = !matchMedia.matches;
        this.isHamburgerBorderShow = !matchMedia.matches;
        this.openHamburgerFunc();
      },
      changeOverlayState() {
        this.isOverlayShow = !this.isOverlayShow;
      },
      checkHeaderItemState(current) {
        for (let i = 1; i < 6; i += 1) {
          if (this.$refs[`header${i}`].isOpen === true) {
            if (current === this.$refs[`header${i}`]) return;
            this.$refs[`header${i}`].isOpen = false;
          }
        }
      },
      checkFooterItemState(current) {
        for (let i = 1; i < 6; i += 1) {
          if (this.$refs[`footer${i}`].isOpen === true) {
            if (current === this.$refs[`footer${i}`]) return;
            this.$refs[`footer${i}`].isOpen = false;
          }
        }
      },
    },
  });
}

export default function header() {
  const currentWidth = window.innerWidth;
  const matchMedia = window.matchMedia('(max-width:999px)');

  // eslint-disable-next-line no-undef
  Vue.component('header-item', {
    props: {
      name: {
        type: String,
        default: '_top',
      },
      link: {
        type: String,
        default: '/',
      },
    },
    data() {
      return {
        isOpen: false,
      };
    },
    mounted() {
      window.addEventListener('resize', this.checkMediaOpen);
      this.checkMediaOpen();
    },
    methods: {
      toggleLink(event) {
        if (matchMedia.matches) {
          event.preventDefault();
          this.$emit('check-header-item-state', this);
          this.isOpen = !this.isOpen;
        }
      },
      checkMediaOpen() {
        if (currentWidth === window.innerWidth) {
          return;
        }
        this.isOpen = false;
      },
      mouseEnterShow() {
        if (this.name !== '_company' && this.name !== '_business') return;
        if (!matchMedia.matches) {
          this.isOpen = true;
        }
        this.$emit('change-overlay-state');
      },
      mouseLeaveHide() {
        if (!matchMedia.matches) {
          this.isOpen = false;
        }
        this.$emit('change-overlay-state');
      },
      enter(enterElement) {
        const enterEl = enterElement;
        const enterElHeight = enterEl.getBoundingClientRect().height;
        enterEl.style.height = '0';
        // eslint-disable-next-line no-unused-expressions
        enterEl.offsetHeight; // reflow
        enterEl.style.height = `${enterElHeight}px`;
      },
      afterEnter(afterEnterElement) {
        const afterEnterEl = afterEnterElement;
        afterEnterEl.style.height = null;
      },
      leave(leaveElement) {
        const leaveEl = leaveElement;
        const leaveElHeight = leaveEl.getBoundingClientRect().height;
        leaveEl.style.height = `${leaveElHeight}px`;
        // eslint-disable-next-line no-unused-expressions
        leaveEl.offsetHeight; // reflow
        leaveEl.style.height = '0';
      },
      afterLeave(afterLeaveElement) {
        const afterLeaveEl = afterLeaveElement;
        afterLeaveEl.style.height = null;
      },
    },
    template: `
      <li class="l-headerAccordion__item" :class="{_open: isOpen}" @mouseenter="mouseEnterShow" @mouseleave="mouseLeaveHide">
        <a :href="link" class="l-headerAccordion__link u-uppercase" @click="toggleLink(event)" :class="name"><slot name="parent"></slot></a>
        <transition name="header-accordion" @enter="enter" @after-enter="afterEnter" @leave="leave" @after-leave="afterLeave">
          <div class="l-headerAccordion__child" v-show="isOpen"><slot name="child"></slot></div>
        </transition>
      </li>
    `,
  });
}

export default function footer() {
  const currentWidth = window.innerWidth;
  const matchMedia = window.matchMedia('(max-width:999px)');

  // eslint-disable-next-line no-undef
  Vue.component('footer-item', {
    props: {
      link: {
        type: String,
        default: '/',
      },
    },
    data() {
      return {
        isOpen: false,
      };
    },
    mounted() {
      window.addEventListener('resize', this.checkMediaOpen);
      this.checkMediaOpen();
    },
    methods: {
      toggleLink(event) {
        if (matchMedia.matches) {
          event.preventDefault();
          this.$emit('check-footer-item-state', this);
          this.isOpen = !this.isOpen;
        }
      },
      checkMediaOpen() {
        if (currentWidth === window.innerWidth) {
          if (matchMedia.matches) {
            return;
          }
        }
        this.isOpen = !matchMedia.matches;
      },
      enter(enterElement) {
        const enterEl = enterElement;
        const enterElHeight = enterEl.getBoundingClientRect().height;
        enterEl.style.height = '0';
        // eslint-disable-next-line no-unused-expressions
        enterEl.offsetHeight; // reflow
        enterEl.style.height = `${enterElHeight}px`;
      },
      afterEnter(afterEnterElement) {
        const afterEnterEl = afterEnterElement;
        afterEnterEl.style.height = null;
      },
      leave(leaveElement) {
        const leaveEl = leaveElement;
        const leaveElHeight = leaveEl.getBoundingClientRect().height;
        leaveEl.style.height = `${leaveElHeight}px`;
        // eslint-disable-next-line no-unused-expressions
        leaveEl.offsetHeight; // reflow
        leaveEl.style.height = '0';
      },
      afterLeave(afterLeaveElement) {
        const afterLeaveEl = afterLeaveElement;
        afterLeaveEl.style.height = null;
      },
    },
    template: `
      <li class="l-footerAccordion__item" :class="{_open: isOpen}">
        <a :href="link" class="l-footerAccordion__link u-uppercase" lang="en" @click="toggleLink(event)"><slot name="parent"></slot></a>
        <transition name="footer" @enter="enter" @after-enter="afterEnter" @leave="leave" @after-leave="afterLeave">
          <div class="l-footerAccordion__child" v-show="isOpen"><slot name="child"></slot></div>
        </transition>
      </li>
    `,
  });
}

export default function hoverBlowing() {
  const matchMedia = window.matchMedia('(max-width:999px)');

  // eslint-disable-next-line no-undef
  Vue.component('blowing-item', {
    data() {
      return {
        isShow: false,
      };
    },
    methods: {
      mouseEnterShow() {
        if (!matchMedia.matches) {
          this.isShow = !this.isShow;
        }
      },
      mouseLeaveHide() {
        if (!matchMedia.matches) {
          this.isShow = !this.isShow;
        }
      },
      mouseMove(event) {
        if (!matchMedia.matches) {
          const windowX = this.$refs.link.getBoundingClientRect().left;
          const windowY = this.$refs.link.getBoundingClientRect().top;
          const hoverX = event.clientX;
          const hoverY = event.clientY;
          const transformX = hoverX - windowX;
          const transformY = hoverY - windowY;
          this.$refs.blowing.setAttribute(
            'style',
            `transform: translate(${transformX}px, ${transformY}px);`,
          );
        }
      },
    },
    template: `
      <li class="c-listOblique__item">
        <div class="c-listOblique__itemInner" @mouseenter="mouseEnterShow" @mouseleave="mouseLeaveHide" @mousemove="mouseMove" ref="link">
          <slot name="link"></slot>
        </div>
        <transition name="blowing">
          <div class="c-listOblique__blowing" :class="{_show: isShow}" v-show="isShow" ref="blowing" v-cloak>
            <slot name="blowing"></slot>
          </div>
        </transition>
      </li>
    `,
  });
}

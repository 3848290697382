export default function scrollTarget($target, duration, callback = $.noop) {
  const matchMedia = window.matchMedia('max-width: 999px');
  let targetY;
  const HEADER_HEIGHT_SP = 75;
  const HEADER_HEIGHT_PC = 90;

  if ($target.length > 0) {
    if (matchMedia.matches) {
      targetY = Math.ceil($target.offset().top - HEADER_HEIGHT_SP);
    } else {
      targetY = Math.ceil($target.offset().top - HEADER_HEIGHT_PC);
    }

    if (duration === 0) {
      $(window).scrollTop(targetY);
      callback();
    } else {
      $.when(
        $('html, body').animate({ scrollTop: targetY }, duration, 'swing'),
      ).done(() => {
        callback();
      });
    }
  }
}

export default function loadingFadein() {
  // 下層ページh1
  function loadingFadeinHeading() {
    const fadeinHeadingWrap = document.getElementById('js-fadein-heading');
    if (fadeinHeadingWrap === null) return;
    const fadeinHeading = document.querySelectorAll('.loadingFadeinLeft');
    window.addEventListener('load', () => {
      function fadeinWrapFunc() {
        fadeinHeadingWrap.classList.add('_fadein');
      }
      setTimeout(fadeinWrapFunc, 1000);
      fadeinHeading.forEach((el, i) => {
        setTimeout(() => {
          function fadeinFunc() {
            el.classList.add('_fadein');
          }
          setTimeout(fadeinFunc, i * 80);
        }, 1000);
      });
    });
  }
  // 下層ページメインビジュアル
  function loadingFadeinImage() {
    const fadeinImage = document.getElementById('js-fadein-heading-image');
    if (fadeinImage === null) return;
    window.addEventListener('load', () => {
      fadeinImage.classList.add('_fadein');
    });
  }
  // .l-contents
  function loadingFadeinContents() {
    const fadeinContents = document.getElementById('js-fadein-contents');
    if (fadeinContents === null) return;
    window.addEventListener('load', () => {
      function fadeinFunc() {
        fadeinContents.classList.add('_fadein');
      }
      setTimeout(fadeinFunc, 1100);
    });
  }
  // 実行
  loadingFadeinHeading();
  loadingFadeinImage();
  loadingFadeinContents();
}

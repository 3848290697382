import vueInstance from './vueInstane';
import hashScroll from './hashScroll';
import debounce from './debounce';
import pagetop from './pagetop';
import loadingFadein from './loadingFadein';
import modal from './modal';
import slider from './slider';
import cookie from './cookie';

vueInstance();
hashScroll();
debounce();
pagetop();
loadingFadein();
modal();
slider();
cookie();
// eslint-disable-next-line no-undef
objectFitImages('img');
